body, html {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #e9f2f6;
}

#app-container {
  background-color: #e9f2f6;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  width: 80%;
  height: auto;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#api-key-container {
  text-align: center;
  margin-bottom: 10px;
}

#api-key-input {
  width: 100%;
  max-width: 475px;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 10px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* This ensures padding and border are included in the width */
}

#search-box {
  width: 100%;
  max-width: 475px; /* Ensuring the search box is aligned with button container */
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 15px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#logo {
  max-width: 200px;
  margin: 20px 0;
}

button, .clear-button, .back-button {
  height: 35px;
  padding: 5;
  line-height: 35px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
  flex: 1;
  margin: 0 5px;
  max-width: calc((100% - 20px) / 4);
}

button:hover, .clear-button:hover, .back-button:hover {
  background-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#response-container {
  text-align: center;
  background-color: #e8eaf6;
  border-left: 5px solid #007bff;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 600px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin: 10px 0;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.spinner {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 10px 0;
  width: 500px;
}

footer {
  margin-top: 20px;
  text-align: center;
}

.footer-icon {
  display: inline-block;
  padding: 10px;
}

.icon {
  width: 24px;
  height: auto;
  transition: transform 0.3s ease;
}

.footer-icon:hover .icon {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  #api-key-input, #search-box, .button-container {
    max-width: 100%;
  }

  button, .clear-button, .back-button {
    flex: 1;
    margin: 0 2px;
    max-width: calc((100% - 25px) / 3);
  }
}
