/* welcomepage.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #e9f2f6; /* Background color of the page */
}

.welcome-container {
    text-align: center;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff; /* Background color for the container */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.app-blocks {
    text-align: center;
    display: flex;
    justify-content: center; /* Align blocks horizontally in the center */
    flex-wrap: wrap; /* Wrap blocks to next line on small screens */
    gap: 20px; /* Space between blocks */
    margin-top: 20px; /* Space below the nav-bar */
}

.app-block {
    width: calc(33.333% - 20px); /* Subtract gap size from width */
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for each block */
    margin-bottom: 20px; /* Space below each block */
}

.app-icon {
    height: 100px; /* Set a fixed height for your icons */
    margin-bottom: 20px; /* Space between icon and header */
}

.app-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
}

.app-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.app-block button {
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center; /* Center text horizontally */
    line-height: 20px; /* Adjust line height to center text vertically */
}

.app-block button:hover {
    background-color: #0056b3;
}

@media (max-width: 992px) {
    .app-block {
        width: calc(50% - 20px); /* 2 blocks per row on medium screens */
    }
}

@media (max-width: 768px) {
    .app-block {
        width: calc(100% - 20px); /* Full width on small screens */
    }
}
